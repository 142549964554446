// import { REACT_APP_BASE_URL } from "@env";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { isSuperAdmin } from "../lib/helperFuctions";
import { toast } from "react-toastify";
// type prepareHeaders = (
//   headers: Headers,
//   api: {
//     getState: () => unknown
//     extra: unknown
//     endpoint: string
//     type: 'query' | 'mutation'
//     forced: boolean | undefined
//   }
// ) => Headers | void

/**
 * Base API service to be used across the application.
 * Endpoints can be injected using the .injectEndpoints method.
 */

// Create a singleton for storing the navigate function
let navigateFunction = null;

export const setNavigateFunction = (navigate) => {
  navigateFunction = navigate;
};

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_BASE_URL}/${isSuperAdmin() ? "admin" : "store"}`,
  // baseUrl: `${"http://localhost:5000/api"}/${isSuperAdmin() ? "admin" : "store"}`,
  prepareHeaders: async (headers) => {
    const token = await localStorage.getItem("authToken");
    headers.set("Authorization", `Bearer ${token}`);
    // console.log(
    //   "Base URL:",
    //   `${"http://localhost:5000/api"}/${isSuperAdmin() ? "admin" : "store"}`
    // );

    return headers;
  },
  // credentials: "include",
  /*
   * This is added to timeout the API in case no response is received in 10s
   */
  timeout: 10000,
});

// Create a custom base query with error handling
const baseQueryWithReauth = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  console.log("result", result);
  if (result?.error?.originalStatus === 401) {
    // Clear auth data
    localStorage.removeItem("authToken");
    if (navigateFunction) {
      navigateFunction("/login");
      toast.error("Session expired, please login again");
    } else {
      window.location.href = "/login";
    }
  }

  return result;
};

const baseApiService = createApi({
  reducerPath: "baseApiReducer",
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});

export default baseApiService;
