import React, { useCallback, useEffect, useState, useRef } from "react";
import { Card, Grid, IconButton } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import { useTranslation } from "react-i18next";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ExpandableTable from "examples/Tables/ExpandableTable";
import {
  useLazyGetNewOrdersQuery,
  useLazyMarkOrderAsPrintedQuery,
} from "../../services/new-order.service";
import { useUpdateOrderMutation } from "../../services/order.service";
import { toast } from "react-toastify";
import MDButton from "components/MDButton";

function CurrentOrder() {
  const { t } = useTranslation();

  const [orders, setOrders] = useState({
    total: 0,
    data: [],
  });
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [printItem, setPrintItem] = useState();

  const [getOrders] = useLazyGetNewOrdersQuery();
  const [updateOrder] = useUpdateOrderMutation();
  const [markAsPrinted] = useLazyMarkOrderAsPrintedQuery();

  // Add ref to store interval ID
  const orderIntervalRef = useRef(null);
  const otherTimerRef = useRef(null); // For any other timers you might have

  const fetchOrders = useCallback(async () => {
    console.log("🔍 Fetching orders...");
    setLoading(true);
    const { data, isSuccess, isError, error } = await getOrders();
    if (isSuccess) {
      setOrders(data);
      if (data.data.length > 0) {
        console.log("✅ Found orders:", data.data.length, "- Stopping interval and printing");
        printOrders(0, data.data);
      }
    }

    if (isError) {
      console.error("❌ Error fetching orders:", error);
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    // Clear any existing intervals first
    if (orderIntervalRef.current) {
      clearInterval(orderIntervalRef.current);
    }

    // Initial fetch
    fetchOrders();

    // Set up the new interval
    orderIntervalRef.current = setInterval(() => {
      fetchOrders();
    }, 9000);

    // Cleanup function
    return () => {
      // Clean up all intervals/timers
      if (orderIntervalRef.current) {
        clearInterval(orderIntervalRef.current);
        orderIntervalRef.current = null;
      }
      if (otherTimerRef.current) {
        clearTimeout(otherTimerRef.current);
        otherTimerRef.current = null;
      }
    };
  }, []); // Empty dependency array for component mount/unmount only

  const printOrders = (i, data) => {
    if (!data || !data[i]) {
      console.error("Invalid data or index");
      return;
    }

    try {
      console.log("Processing order:", data[i]);
      const currentItem = {
        ...data[i],
        mobile: data[i]?.user?.mobile || data[i]?.mobile || "",
      };
      setPrintItem(currentItem);

      // Use the currentItem directly
      setTimeout(() => {
        if (!currentItem) {
          console.log("Print item not set yet, retrying...");
          setTimeout(() => {
            SaveAsTextHandler(i, data, currentItem); // Pass currentItem directly
          }, 1000);
          return;
        }
        SaveAsTextHandler(i, data, currentItem); // Pass currentItem directly
      }, 1500);
    } catch (error) {
      console.error("Error in printOrders:", error);
    }
  };

  const handleOrderUpdate = async (data) => {
    const ordersClone = { ...orders };

    ordersClone.data = ordersClone?.data?.map((product) => {
      if (product?.id === data?.id) {
        product = { ...product, is_printed: data?.is_printed };
      }
      return product;
    });
    setOrders(ordersClone);

    const result = await updateOrder({
      submitData: { status: data?.updatedStatus },
      id: data?.id,
    });
    if ("error" in result) {
      toast.error(result?.error?.data?.message || t("common:SOMETHING_WENT_WRONG"));
    } else {
      toast.success(result?.data?.msg);
    }
  };

  const formatNZDateTime = (dateString) => {
    return new Date(dateString).toLocaleString("en-NZ", {
      timeZone: "Pacific/Auckland",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  const SaveAsTextHandler = async (i, data, currentItem = printItem) => {
    if (!currentItem) {
      toast.info("No print item available");
      return;
    }

    try {
      // Create text content using currentItem
      const textContent = [
        // Center text with 52-character width
        `${"SOUL PIZZA".padStart(30).padEnd(52)}`,
        `${"GST No. 111-111-111".padStart(33).padEnd(52)}`,
        `${`Invoice #${currentItem?.id || "N/A"}`
          .padStart(30 + (currentItem?.id?.toString()?.length || 3) / 2)
          .padEnd(52)}`,
        `PRICE:${`$${Number(currentItem?.amount || 0).toFixed(2)}`.padStart(46)}`,
        "",
        `${String(currentItem?.type ? currentItem.type.toUpperCase() : "N/A")
          .padStart(30)
          .padEnd(52)}`,

        "",
        // Order details with consistent width - ensure all values are strings

        `Customer: ${String(currentItem?.customer_name || "N/A").padEnd(42)}`,
        `Contact: ${String(currentItem?.mobile || "N/A").padEnd(43)}`,
        `Address: ${String(currentItem?.address || "N/A").padEnd(43)}`,
        `Order Time: ${String(formatNZDateTime(currentItem?.createdAt) || "N/A").padEnd(40)}`,
        `Pickup Time: ${String(formatNZDateTime(currentItem?.pickup_time) || "N/A").padEnd(40)}`,
        "",
        "----------------------------------------------------",
        "ITEM                                          AMOUNT",
        "----------------------------------------------------",

        ...(currentItem?.orderItems?.map((item) => {
          const lines = [
            `${String(item?.quantity || 0).padStart(2)}x ${String(
              item?.product?.name || "Unknown"
            )}${`$${Number((item?.price || 0) * (item?.quantity || 1)).toFixed(2)}`.padStart(
              52 - String(item?.product?.name || "Unknown").length - 4
            )}`,
          ];

          if (item?.comboDeal && item?.comboDealItems?.length > 0) {
            item.comboDealItems.forEach((comboItem) => {
              const comboText = `     - ${String(comboItem?.name || "Unknown")} (${String(
                comboItem?.sub_category || ""
              )})`;
              lines.push(comboText.padEnd(52));
            });
          }

          if (item?.size && item?.crust_type) {
            lines.push(
              `     - Size: ${String(item?.size || "N/A")}  Crust: ${String(
                item?.crust_type || "N/A"
              )}`.padEnd(52)
            );
          }

          if (item?.gluten_free) {
            lines.push(`Gluten Free: ${String(item?.gluten_free || "N/A").padEnd(43)}`);
          }
          if (item?.instruction) {
            lines.push(`Instructions: ${String(item?.instruction || "N/A").padEnd(43)}`);
          }

          return lines.join("\n");
        }) || []),
        "----------------------------------------------------",
        `Subtotal:${`$${Number(currentItem?.amount - (currentItem?.tax || 0)).toFixed(2)}`.padStart(
          43
        )}`,
        `Total:${`$${Number(currentItem?.amount || 0).toFixed(2)}`.padStart(46)}`,
        "",
      ].join("\n");

      // Create and download text file
      const blob = new Blob([textContent], { type: "text/plain" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `invoice-${currentItem?.id}.txt`;

      document.body.appendChild(link);

      // Modified click and mark-as-printed logic
      try {
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        //Mark order as printed
        const result = await markAsPrinted(currentItem.id);
        if (result.error) {
          console.error("Error marking order as printed:", result.error);
          toast.error("Failed to mark order as printed");
        }

        setTimeout(() => {
          if (i < data.length - 1) {
            printOrders(i + 1, data);
          }
        }, 1000);
      } catch (clickError) {
        console.error("Error clicking download link:", clickError);
        toast.error("Failed to download receipt");
      }
    } catch (error) {
      console.error("Error saving text file:", error);
      if (i < data.length - 1) {
        printOrders(i + 1, data);
      }
    }
  };

  const cellList = [
    { id: "blank", name: "", alignment: "", selector: "" },
    { id: "orderId", name: "Order ID", alignment: "center", selector: "id", isId: true },
    { id: "date", name: "Date", alignment: "center", selector: "createdAt", isDate: true },
    {
      id: "customerName",
      name: "Customer Name",
      alignment: "center",
      selector: "customer_name",
      isText: true,
    },
    {
      id: "pickupTime",
      name: "Pick-up Time",
      alignment: "center",
      selector: "pickup_time",
      isDate: true,
    },
    { id: "amount", name: "Amount", alignment: "right", selector: "amount", isPrice: true },
  ];

  const subCellList = [
    {
      id: "productName",
      name: "Product Name",
      alignment: "",
      selector: "product.name",
      isText: true,
    },
    { name: "Combo Items", alignment: "center", selector: "comboDealItems", isChip: true },
    { id: "quantity", name: "Quantity", alignment: "center", selector: "quantity", isText: true },
    {
      id: "defaultIngredients",
      name: "Default Ingredients",
      alignment: "",
      selector: "requiredIngrediants",
      isChip: true,
    },
    {
      id: "customIngredients",
      name: "Customized Ingredients",
      alignment: "",
      selector: "customIngrediants",
      isChip: true,
    },
    { id: "size", name: "Size", alignment: "center", selector: "size", isText: true },
    {
      id: "crustType",
      name: "Crust Type",
      alignment: "center",
      selector: "crust_type",
      isCrust: true,
    },
    {
      id: "glutenFree",
      name: "Gluten Free",
      alignment: "center",
      selector: "gluten_free",
      isBoolean: true,
    },
    { id: "amount", name: "Amount", alignment: "right", selector: "price", isPrice: true },
  ];

  // If you need to manually stop/start the interval
  const stopOrderInterval = () => {
    if (orderIntervalRef.current) {
      clearInterval(orderIntervalRef.current);
      orderIntervalRef.current = null;
    }
  };

  const startOrderInterval = () => {
    // Clear any existing interval first
    stopOrderInterval();

    // Set up new interval
    orderIntervalRef.current = setInterval(() => {
      fetchOrders();
    }, 5000);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  {t("Current Order")}
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <ExpandableTable
                  orderList={orders?.data}
                  count={orders?.total || 0}
                  cellList={cellList}
                  subCellList={subCellList}
                  expandTitle={t("ORDER_DETAILS_TITLE")}
                  expandProperty="orderItems"
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  page={page}
                  setPage={setPage}
                  setSearch={setSearch}
                  handleOrderUpdate={handleOrderUpdate}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CurrentOrder;
