import baseApiService from "./base-api.service";

const ORDER_TAG = "NEW";

/**
 * Service to fetch data from the API for an endpoint.
 */
const OrderApi = baseApiService
  .enhanceEndpoints({
    addTagTypes: [ORDER_TAG],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getNewOrders: build.query({
        query: (params) => ({
          url: "/newOrder",
        }),
      }),
      markOrderAsPrinted: build.query({
        query: (id) => ({
          url: `/orders/${id}/mark-printed`,
          method: "GET",
        }),
        invalidatesTags: ["Orders"],
      }),
    }),
    overrideExisting: true,
  });

export const { useLazyGetNewOrdersQuery, useLazyMarkOrderAsPrintedQuery } = OrderApi;
