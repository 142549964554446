import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import MDBox from "components/MDBox";
import Breadcrumbs from "examples/Breadcrumbs";
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";
import { useMaterialUIController, setTransparentNavbar, setMiniSidenav } from "context";
import { Icon, Menu, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import ChangePasswordDialog from "components/ChangePasswordDialog";
import { handleChange, validate } from "lib/joiHelper";
import changePasswordSchema from "./schema";
import { isSuperAdmin } from "../../../lib/helperFuctions";

function DashboardNavbar({ absolute, light, isMini }) {
  const initialData = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const [data, setData] = useState(initialData);
  const [error, setError] = useState(initialData);
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { transparentNavbar, fixedNavbar, darkMode, miniSidenav } = controller;
  const route = useLocation().pathname.split("/").slice(1);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleMenuClose();
    localStorage.removeItem("authToken");
    navigate("/sign-in");
  };

  const handleProfile = () => {
    handleMenuClose();
    navigate("/profile");
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    handleMenuClose();
  };

  const schema = changePasswordSchema(t);

  const onBlurHandler = (e) => {
    const { dataClone, errorClone } = handleChange(e, schema, data, error);
    setData(dataClone);
    if (
      e.target.name !== "currentPassword" &&
      dataClone?.confirmPassword &&
      dataClone?.newPassword !== dataClone?.confirmPassword
    ) {
      errorClone.confirmPassword = t("signIn:PASSWORD_NOT_MATCH");
    }

    setError(errorClone);
  };
  const onSubmitHandler = () => {
    const errorClone = validate(data, schema);
    if (errorClone) {
      setError(errorClone);
    } else if (data?.newPassword !== data?.confirmPassword) {
      const matchError = { confirmPassword: t("signIn:PASSWORD_NOT_MATCH") };
      setError(matchError);
    } else {
      console.log("Form is submitted", data);
    }
  };

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <ChangePasswordDialog
        dialogOpen={dialogOpen}
        handleDialogClose={handleDialogClose}
        onBlurHandler={onBlurHandler}
        onSubmitHandler={onSubmitHandler}
        error={error}
      />
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <IconButton color="inherit" sx={navbarMobileMenu} onClick={handleMiniSidenav}>
            <Icon fontSize="medium">{miniSidenav ? "menu_open" : "menu"}</Icon>
          </IconButton>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <div className="account-menu-root">
              <IconButton className="account-menu-button" onClick={handleMenuOpen} color="inherit">
                <AccountCircle />
              </IconButton>

              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                {!isSuperAdmin() && (
                  <div>
                    <MenuItem onClick={handleProfile}>
                      <PersonIcon />
                      <span style={{ marginLeft: "10px" }}>{t("common:MY_PROFILE")}</span>
                    </MenuItem>
                    <MenuItem onClick={() => setDialogOpen(true)}>
                      <SettingsIcon />
                      <span style={{ marginLeft: "10px" }}>{t("common:CHANGE_PASSWORD")}</span>
                    </MenuItem>
                  </div>
                )}
                <MenuItem onClick={handleLogout}>
                  <LogoutIcon />
                  <span style={{ marginLeft: "10px" }}>{t("common:LOGOUT")}</span>
                </MenuItem>
              </Menu>
            </div>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
